// custom functions
const FE = {
	location: window.location,

    Lightbox: {
	    init: function () {
            var lightbox = new Tobii({zoom: false});
        }
    },

    MobileMenu: {
        init: function() {
            const burgerIcon = document.getElementById('burger-icon');
            const navMain = document.getElementById('nav-main');
            burgerIcon.addEventListener("click", function () {
                if ( navMain.classList.contains('active') ) {
                    this.setAttribute('aria-expanded', false);
                    navMain.classList.remove('active');
                } else {
                    this.setAttribute('aria-expanded', true);
                    navMain.classList.add('active');
                }
            });
        }

    },

    HeroSlider: {
        init: function () {
            var sliderContainer = document.querySelector('.ce-hero-slider-slides');
            var slides = document.querySelectorAll('.ce-hero-slider-slides figure');
            //console.log(sliderContainer, slides);
            this.setupSliderMarkup(sliderContainer, slides);
        },
        setupSliderMarkup: function (sliderContainer, slides, controls=false) {
            var sliderEl = document.createElement('div');
            var sliderTrack = '<div class="glide__track" data-glide-el="track"></div>';
            var sliderControls ='<div class="glide__arrows" data-glide-el="controls"><button class="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button><button class="glide__arrow glide__arrow--right" data-glide-dir=">">next</button></div>';

            var slidesCount = slides.length;
            for (var i=0; i < slidesCount; i++) {
                slides[i].classList.add('glide__slide');
            }

            sliderEl.classList.add('glide');
            sliderContainer.parentNode.insertBefore(sliderEl, sliderContainer);
            sliderContainer.classList.add('glide__slides');

            var sliderInnerContent = sliderTrack;
            if (controls === true) {
                sliderInnerContent += sliderControls;
            }
            sliderEl.innerHTML = sliderInnerContent;

            var sliderTrackEl = sliderEl.querySelector('.glide__track');
            sliderTrackEl.appendChild(sliderContainer);

            new Glide('.ce-hero_slider .glide', {
                type: 'carousel',
                autoplay: 5000,
                hoverpause: false
            }).mount();
        }
    },

    YouTube: {
        init: function () {
            document.querySelectorAll('.hero-type-video .hero-image-title a').forEach(function (videoItem) {
                videoItem.addEventListener('click', FE.YouTube.checkVideoLoading);
                FE.YouTube.addUserConsent(videoItem.closest('.hero-type-video'));
            });
        },
        checkVideoLoading: function (e) {
            e.preventDefault();
            //console.log(e);
            const videoArea = e.target.closest('.hero-type-video');
            // check potential permission via consent manager
            videoArea.querySelector('.youtube-consent').classList.add('show');
        },
        loadVideo: function (videoItem) {
            const urlParts = videoItem.querySelector('a').href;
            const youTubeId = urlParts.split('v=')[1];

            //console.log(youTubeEmbedCode);
            //console.log(videoItem);

            videoItem.innerHTML = FE.YouTube.getYouTubeEmbedCode(youTubeId);
        },
        getYouTubeEmbedCode: function (youTubeId) {
            return '<div class="video"><iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/' + youTubeId + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';
        },
        addUserConsent: function (videoArea) {
            const youTubeConsentHtml = `An dieser Stelle laden wir ein externes Video von YouTube.<br>
                                        Mir ist bewusst, dass dabei Daten mit den YouTube Servern ausgetauscht werden.<br>
                                        Weitere Informationen zu den <a href="https://policies.google.com/technologies/cookies?hl=de" target="_blank" rel="noopener">YouTube Datenschutzbedingungen</a>.
                                        <div class="btn-group"><button class="btn-yt-confirm">Geht klar</button><button class="btn-yt-deny">möchte ich nicht</button>`;

            const youTubeConsent = document.createElement('div');
            youTubeConsent.classList.add('youtube-consent');
            youTubeConsent.innerHTML = `<div class="youtube-consent-inner">${youTubeConsentHtml}</div>`;
            videoArea.append(youTubeConsent);
            videoArea.querySelector('.btn-yt-confirm').addEventListener('click', function (e) {
                FE.YouTube.loadVideo(e.target.closest('.hero-type-video'));
            });
            videoArea.querySelector('.btn-yt-deny').addEventListener('click', function (e) {
                e.target.closest('.youtube-consent').classList.remove('show');
            });
        }
    }

};

// init custom functions
window.addEventListener("DOMContentLoaded", function () {

    FE.MobileMenu.init();
    if ( document.querySelectorAll('.lightbox').length ) {
        FE.Lightbox.init();
    }
    if ( document.querySelectorAll('.ce-hero-slider-slides figure').length > 1 ) {
        FE.HeroSlider.init();
    }
    if ( document.querySelector('.hero-type-video .hero-image-title a') ) {
        FE.YouTube.init();
    }
});